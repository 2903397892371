import {SiOpenaigym} from 'react-icons/si'
import  {BsRocketTakeoff} from 'react-icons/bs'
import {BiWorld} from 'react-icons/bi'
import {MdMovieEdit} from 'react-icons/md'
import {BiCamera} from 'react-icons/bi'

/*------------------------------------- Routes --------------------------- */

export const links = [
    // {
    //     name: "Home",
    //     path: '/'
    // },
    {
        name: "About",
        path: '/about'
    },
    {
        name: "Services",
        path: '/services'
    },    
  
    {
        name: "Gallery",
        path: '/gallery'
    }, 

    {
        name: "Team",
        path: '/team'
    }, 
    {
        name: "Contact",
        path: '/contact'
    },

    //{
    //     name: "NotFound",
    //     path: 'notfound'
    //},
  
]


/*------------------- Programs Content ------------------*/


export const programs = [
    {
        id: 1,
        icon: <SiOpenaigym/>,
        title: "Digital Media Marketing",
        info: "In a digital world driven by connections, our digital media marketing services are designed to connect your brand with your audience. From captivating social media campaigns to strategic content creation, we craft digital narratives that resonate and engage",
        path: "/programs/111"
    },
    {
        id: 2,
        icon: <SiOpenaigym/>,
        title: "Web Development",
        info: "Embark on a digital journey with our web development expertise. Our team harnesses the power of cutting-edge technologies like React JS, HTML5, and CSS3 to create websites that not only impress visually but also function seamlessly across devices.",
        path: "/programs/222"
    },
    {
        id: 3,
        icon: <SiOpenaigym/>,
        title: "SEO (Search Engine Optimization",
        info: "Be seen where it matters most - on search engines. Our SEO services are tailored to enhance your online visibility, driving organic traffic and ensuring your brand stands out in a competitive digital landscape.",
        path: "/programs/333"
    },
    {
        id: 4,
        icon: <SiOpenaigym/>,
        title: "Video Production",
        info: "Conquer the visual world of YouTube with our comprehensive services. From optimizing your channel for maximum impact to crafting engaging video content, we help you navigate the ever-evolving realm of online video. Captivate your audience with visually stunning videos that tell your story. ",
        path: "/programs/444"
    }
]


/*-------------------------------Values Content ----------------------*/

export const values = [
    {
        id: 1,
        icon: <BsRocketTakeoff/>,
        title: "Empower Your Brand",
        desc: "Unlock the power of digital media with AHB innovations. Whether it's crafting a compelling website, igniting social media campaigns, or producing mesmerizing videos, we amplify your online presence.."
    },
    {
        id: 2,
        icon: <BiWorld/>,
        title: "Web Solutions That Stand Out",
        desc: "Our web development expertise spans a myriad of technologies – from A to Z. We build websites that aren't just functional; they're captivating journeys that users love to explore."
    },
    {
        id: 3,
        icon: <MdMovieEdit/>,
        title: "Unleash Your Story",
        desc: "Let our videography team bring your narrative to life. From captivating corporate videos to spellbinding animations, we turn concepts into visual masterpieces."
    },
    {
        id: 4,
        icon: <BiCamera/>,
        title: "Capture the Extraordinary",
        desc: "Our photography is a window into your world. Every shot tells a story, and our lens captures the essence of your brand with every click."
    }
]


/*-------------------------------- FAQ's Content------------------------*/

export const faqs = [
    {
        id: 1,
        question: "What services does AHB INNOVATIONS  offer?",
        answer: "AHB INNOVATIONS offers a variety of services including social media management, paid advertising campaigns, content marketing, email marketing, website design and development, branding and analytics."
    },
    {
        id: 2,
        question: "How can  AHB INNOVATIONS help my business grow?",
        answer: "AHB INNOVATIONS can help your business grow by implementing effective digital marketing strategies tailored to your target audience. We can increase your brand visibility, drive more traffic to your website, and ultimately convert leads into customers."
    },
    {
        id: 3,
        question: "Do we have experience in your industry?",
        answer: "AHB INNOVATIONS has experience working with a wide array of industries. You can inquire about our portfolio or case studies to see if we have experience relevant to your specific industry."
    },
    {
        id: 4,
        question: "How do we ensure the success of an SEO campaign?",
        answer: "AHB INNOVATIONS  employs white-hat SEO techniques such as keyword optimization, quality content creation, link building, and website optimization to improve search engine rankings and increase organic traffic."
    },
    {
        id: 5,
        question: "Can we design a website from scratch?",
        answer: "Yes, AHB INNOVATIONS offers website design and development services. We can create custom websites tailored to your brand and business objectives, ensuring we are user-friendly and responsive across various devices."
    },
    {
        id: 6,
        question: "How can I get started with AHB INNOVATION's services?",
        answer: "To get started, you can visit our website, contact our sales or customer service team, and discuss your business goals. We will likely offer a free consultation to understand your needs and recommend suitable services to meet your objectives."
    }
]


/*----------------------------- Testimonials Content ----------------------------*/ 


export const testimonials = [
    {
        id: 1,
        name: "Sarah Johnson",
        quote: "AHB innovationss has been the driving force behind our digital transformation. Their strategic insights and creative brilliance have elevated our brand's online presence.",
        job: "CEO - TechHub Inc",
        avatar: require("./images/avatar1.jpg")
    },
    {
        id: 2,
        name: "Alex Roberts",
        quote: "From web development to content creation, AHB innovationss delivers nothing short of excellence. They have been pivotal in our digital journey." ,
        job: "Founder - BrightStart Innovations",
        avatar: require("./images/avatar2.jpg")
    },
    {
        id: 3,
        name: "Emily Brown",
        quote: "A visionary team that doesn't just meet expectations, but exceeds them. AHB innovationss' dedication to quality and innovation is truly commendable.",
        job: "Marketing Director - SparkleUp Ltd",
        avatar: require("./images/avatar3.jpg")
    }
]



/*----------------------------------------------- Services Content ------------------------------- */


export const services = [
    {
        id: 1,
        name: 'Digital Media Marketing',
        desc: 'In a digital world driven by connections, our digital media marketing services are designed to connect your brand with your audience. From captivating social media campaigns to strategic content creation, we craft digital narratives that resonate and engage.',
    },

    {
        id: 2,
        name: 'Web Development',
        desc: 'Embark on a digital journey with our web development expertise. Our team harnesses the power of cutting-edge technologies like React JS, HTML5, and CSS3 to create websites that not only impress visually but also function seamlessly across devices',
    },

    {
        id: 3,
        name: 'SEO (Search Engine Optimization)',
        desc: 'Be seen where it matters most - on search engines. Our SEO services are tailored to enhance your online visibility, driving organic traffic and ensuring your brand stands out in a competitive digital landscape.',
    }, 

    {
        id: 4,
        name: 'YouTube Services',
        desc: 'Conquer the visual world of YouTube with our comprehensive services. From optimizing your channel for maximum impact to crafting engaging video content, we help you navigate the ever-evolving realm of online video.',
    }, 

    {
        id: 5,
        name: 'Video Production',
        desc: 'Captivate your audience with visually stunning videos that tell your story. From concept to creation, our video production team transforms ideas into captivating visual narratives that leave a lasting impression.',
    },

    {
        id: 6,
        name: 'Graphic Designing',
        desc: 'Captivate your audience with visually stunning videos that tell your story. From concept to creation, our video production team transforms ideas into captivating visual narratives that leave a lasting impression.',
    }
    
]


/*------------------------- Team's Content ------------------------------ */

const Trainer1 = require('./images/trainer1.png')
const Trainer2 = require('./images/trainer2.png')
const Trainer3 = require('./images/trainer5.png')
const Trainer4 = require('./images/trainer3.png')
const Trainer5 = require('./images/trainer4.png')
const Trainer6 = require('./images/trainer6.png')


export const trainers = [
    {
        id: 1,
        image: Trainer1,
        name: 'Akhtar Hussain Bukhari',
        job: 'Founder',
        des: "Vision Statement: To revolutionize the digital landscape by infusing creativity and innovation into every project, leaving an indelible mark on the world of digital media. Profile Statement: A visionary with a passion for transforming ideas into digital brilliance, AHB has pioneered AHB Sumos' journey with a commitment to redefining possibilities",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 2,
        image: Trainer2,
        name: 'Ali Haider Bhutta',
        job: 'CEO',
        des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum voluptate veniam ut, perspiciatis saepe mollitia ipsum! Qui, voluptates ipsa exercitationem velit inventore repellat quia quam suscipit itaque, vitae aperiam doloribus.",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 3,
        image: Trainer3,
        name: 'Malik Nayyar Abbas',
        job: 'Team Lead',
        des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum voluptate veniam ut, perspiciatis saepe mollitia ipsum! Qui, voluptates ipsa exercitationem velit inventore repellat quia quam suscipit itaque, vitae aperiam doloribus.",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 4,
        image: Trainer4,
        name: 'Ammar Haider Naqvi',
        job: 'Team Lead ',
        des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum voluptate veniam ut, perspiciatis saepe mollitia ipsum! Qui, voluptates ipsa exercitationem velit inventore repellat quia quam suscipit itaque, vitae aperiam doloribus.",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 5,
        image: Trainer5,
        name: 'Tayyab Rasheed Bhatti',
        job: ' Marketing Head',
        des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum voluptate veniam ut, perspiciatis saepe mollitia ipsum! Qui, voluptates ipsa exercitationem velit inventore repellat quia quam suscipit itaque, vitae aperiam doloribus.",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    },
    {
        id: 6,
        image: Trainer6,
        name: 'Jawad Sherazi',
        job: 'Senior Web Developer',
        des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Earum voluptate veniam ut, perspiciatis saepe mollitia ipsum! Qui, voluptates ipsa exercitationem velit inventore repellat quia quam suscipit itaque, vitae aperiam doloribus.",
        socials: ['https://instagram.com/', 'https://twitter.com/', 'https://facebook.com/', 'https://linkedin.com/']
    }
]