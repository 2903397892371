import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_4.png'
import Card from '../../UI/Card'
import { services } from '../../data'
import './services.css'
import {Link} from 'react-router-dom'

const Services = () => {
  return (
    <>
      <Header title="Our Services" image={HeaderImage}>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Autem natus veniam minima deserunt ducimus nesciunt ipsum praesentium laboriosam quis fugiat qui culpa nulla veritatis saepe in debitis odit, consequatur magnam!
      </Header>
      <div className="container services__headcontainer">
        <h2>Our Services</h2>
      </div>
      <section className="services">
        <div className="container excel__container">
          <h2>Technologies We Excel In</h2>
        </div>
        <div className="container services__container">
          {
            services.map(({id, name, desc}) => {
              return <Card key={id} className={'services'}>
                <h3>{name}</h3>
                <small>{desc}</small>
              </Card>
            })
          }
        </div>
        <div className="container blend__container">
          <p>At AHB Innovations Digital Media, our services aren't just about digital solutions; they're about elevating your brand's digital presence to new heights. With a blend of creativity, technology, and strategy, we empower brands to shine brightly in the digital arena.</p>
        </div>
        <Link to="/contact" className='btn lg services__btn'>Contact Us</Link>
      </section>
    </>
  )
}

export default Services