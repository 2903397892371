import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logoas from '../images/logoas.png'
import './navbar.css'
import {Link} from 'react-router-dom'

function BasicExample() {
  return (
    <Navbar expand="lg" className="">
      <Container className='kos' >
        <Link to="/" className='logo toplog'>
        <img src={Logoas} alt="AHB Sumos"/>

        </Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto nav__links">
            <Link to="/about">About</Link>
            <Link to="/services">Services</Link>
            <Link to="/Gallery">Gallery</Link>
            <Link to="/contact">Contact</Link>
            <NavDropdown title="Our Team" id="basic-nav-dropdown">
                <NavDropdown.Item>
                    <Link to="/team">Team</Link>
                </NavDropdown.Item>

                <NavDropdown.Item>
                    <Link to="/ksi">KSI Team</Link>
                </NavDropdown.Item>
                
                <NavDropdown.Item>
                    <Link to="/dubai">Dubai Team</Link>
                </NavDropdown.Item>
                                
                <NavDropdown.Item>
                    <Link to="/lahore">Lahore Team</Link>
                </NavDropdown.Item>
                                           
                {/* <NavDropdown.Item>
                    <Link to="/oman">Oman Team</Link>
                </NavDropdown.Item> */}
           
              {/* <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item> */}
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default BasicExample;