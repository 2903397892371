import React from 'react'
import Image from '../images/trainer1.png'
// import {BsInstagram} from 'react-icons/bs'
// import {AiOutlineTwitter} from 'react-icons/ai'
// import {FaFacebookF} from 'react-icons/fa'
// import {FaLinkedinIn} from 'react-icons/fa' 
import './ksi.css'

function KSI() {
    let message = ` `;
  return (
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='section-title'>
                        Our KSI Team
                    </h2>
                    <p className='section-subtitle'>{message}</p>
                </div>

                <div className="row team-lead">
                    <div className="col-md-4"></div>

                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Image} alt="" />
                        <h3>Akhtar Hussain Bukhari </h3>
                        <div className='team-info'>
                            <p className='founder'>Team Lead</p>
                            <p><span>Vision Statement: </span> To weave digital excellence into Saudi Arabia's digital narrative, blending innovation and cultural insight.</p>
                            <p><span>Profile Statement: </span> Akhtar Hussain Bukhari's expertise bridges digital technology and Saudi culture, creating experiences that resonate with local audiences.</p>
                            <p><span>Role: </span>Leading the KSA team in shaping digital solutions that captivate and engage, infusing projects with innovation.</p>

                            {/* <ul className='team-icon'>
                            <i><a href='/'><BsInstagram/></a></i>
                                <i><a href='/'><AiOutlineTwitter /></a></i>
                                <i><a href='/'><FaFacebookF /></a></i>
                                <i><a href='/'><FaLinkedinIn /></a></i>
                            </ul> */}
                        </div>
                    </div>
                </div>
                </div>



            </div>
        </div>
    </section>
  )
}

export default KSI