import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import file from '../images/file.pdf'



function Portfolio() {

  return (
    <>
        <div className="d-flex align-itmes-center mt-5 justifiy-content-center">
            <a className="btn lg" href={file} download>Download Our Portfolio</a>
        </div>
    </>
  )
}

export default Portfolio