import { BrowserRouter, Routes , Route } from 'react-router-dom'
import BasicExample from './components/newnav.jsx'
import Home from './pages/home/Home.jsx'
import Services from './pages/services/Services.jsx'
import About from './pages/about/About.jsx'
import Contact from './pages/contact/Contact.jsx'
import Gallery from './pages/gallery/Gallery.jsx'
import Trainers from './pages/trainers/Trainers'
import NotFound from './pages/notFound/NotFound.jsx'
import Dubai from './pages/dubai/Dubai.jsx'
import Lahore from './pages/lahore/Lahore.jsx'
import Ksi from './pages/ksi/Ksi.jsx'
import Oman from './pages/oman/Oman.jsx'

import Footer from './components/Footer.jsx'
import 'bootstrap/dist/css/bootstrap.css'


const App = () => {
  return (
    <BrowserRouter>
      <BasicExample />
        <Routes>
          <Route index element={<Home/>}/>
          <Route path ='services' element={<Services/>}/>
          <Route path ='about' element={<About/>}/>
          <Route path ='contact' element={<Contact/>}/>
          <Route path ='Gallery' element={<Gallery/>}/>
          <Route path ='team' element={<Trainers/>}/>
          
          <Route path ='lahore' element={<Lahore/>}/>
          <Route path ='ksi' element={<Ksi/>}/>
          <Route path ='oman' element={<Oman/>}/>
          <Route path ='dubai' element={<Dubai/>}/>
          
          <Route path ='notfound' element={<NotFound/>}/>
        </Routes>
      <Footer />
    </BrowserRouter>
  )
}

export default App