import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_1.png'
import VisionImage from '../../images/about2.jpg'
import MissionImage from '../../images/about3.jpg'
import './about.css'
import Portfolio from '../../components/Portfolio'


const About = () => {
  return (
    <>

    
    <Header title="About Us" image={HeaderImage}> 
    Lorem ipsum dolor sit amet consectetur adipisicing elit. Aut illum eveniet reiciendis, repellat nulla, aperiam perferendis ad suscipit labore commodi nam quis quidem officia qui illo laudantium fugiat sit placeat.
    </Header>
    <div className="container about__headcontainer">
      <h2>About Us</h2>
    </div>

    <section className="about__Vision">
      <div className="container about__Vision-container">
        <div className="about__section-content">
          <h1>Our Vision</h1>
          <p>At AHB innovations Digital Media, we envision a digital world where creativity knows no bounds. Our vision is to be a catalyst of innovation, empowering brands to embrace the digital frontier with confidence. We strive to reshape the digital landscape, leaving an indelible mark on every project we undertake.</p>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet sequi quidem provident doloribus, facere libero numquam eum tempora? Quod mollitia cum qui accusamus inventore iure repudiandae praesentium nulla odio officiis.
          </p>
          <p>
          Eveniet sequi quidem provident doloribus, facere libero numquam eum tempora? Quod mollitia cum qui accusamus inventore iure repudiandae
          </p> */}
        </div>
        <div className="about__section-image">
          <img src={VisionImage} alt="Our Vision" />
        </div>        
      </div>
    </section>


    <section className="about__Mission">
      <div className="container about__Mission-container">
        <div className="about__section-image">
          <img src={MissionImage} alt="Our Story" />
        </div>
        <div className="about__section-content">
          <h1>Our Mission</h1>
          <p>Our mission is to transform ideas into digital marvels that resonate and engage. With a fusion of imagination and technology, we craft experiences that inspire, captivate, and leave lasting impressions. We're committed to partnering with businesses to amplify their voice and amplify their impact in the digital realm.</p>
          {/* <p>
            Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eveniet sequi quidem provident doloribus, facere libero numquam eum   tempora? Quod mollitia cum qui accusamus inventore iure repudiandae praesentium nulla odio officiis.
          </p>
          <p>
          Eveniet sequi quidem provident doloribus, facere libero numquam eum tempora? Quod mollitia cum qui accusamus inventore iure repudiandae
          </p> */}


        </div>
      </div>
    </section>

          <div className="container portfolio__container">  
            <Portfolio/>
          </div>


    </>
  )
}




export default About