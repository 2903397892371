import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_3.jpg'
import './gallery.css'


const Gallery = () => {
    // Events
    const eventsLength = 6;
    const eventsimages = []

    for(let i= 1; i <= eventsLength; i++) {
        eventsimages.push(require(`../../images/gallery${i}.jpg`))
    }

    // Projects
    const projectsLength = 6;
    const projectImages = [];
    for(let j=1; j <= projectsLength; j++) {
        projectImages.push(require(`../../images/project${j}.jpg`))
    }

    // CSR
    const csrLength = 6;
    const csrImages = [];
    for(let k=1; k <= csrLength; k++) {
        csrImages.push(require(`../../images/csr${k}.jpg`))
    }

    // Collaborations
    const colLength = 6;
    const colImages = [];
    for(let p=1; p <= colLength; p++) {
        colImages.push(require(`../../images/col${p}.jpg`))
    }


  return (
    <>
    <Header title="Our Gallery" image={HeaderImage}>
        Lorem ipsum, dolor sit amet consectetur adipisicing elit. Aspernatur fugiat molestiae, exercitationem minima earum veniam expedita quisquam saepe dignissimos eius natus tenetur soluta consequuntur ab nostrum vero animi accusantium assumenda!
    </Header>
    <div className='container gallery__headcontainer text-center'>
         <h2>Gallery</h2>   
         <h5>Our journey has been marked by a tapestry of successful collaborations. From breathing life into websites to curating captivating visual stories, we've left our digital footprints across industries. Each project is a testament to our dedication to innovation, excellence, and the art of crafting unique narratives.</h5>
    </div>

    {/* Events */}

    <section className='gallery text-center'>
        <h1 className='text-center my-5'>Our Events</h1>
        <h5>Explore the snapshots of our engaging events that bring together innovators, thought leaders, and enthusiasts.</h5>
        <div className='container gallery__container'>
            {
            eventsimages.map((eventsimages, index) => {
                return <article key={index}>
                    <img src={eventsimages} alt={'Gallery Image ${index +1}'} rel="noreffer noopener"/>
                </article>
            })
             }
        </div>
    </section>


    {/* Projects */}


    <section className='gallery text-center'>
        <h1 className='text-center my-5'>Our Projects</h1>
        <h5>Delve into our showcase of digital masterpieces that define excellence in web development, digital marketing, and more.</h5>
        <div className='container gallery__container'>
            {
            projectImages.map((projectImages, index) => {
                return <article key={index}>
                    <img src={projectImages} alt={'Gallery Image ${index +1}'} rel="noreffer noopener"/>
                </article>
            })
             }
        </div>
    </section>

    {/* CSR */}


    <section className='gallery text-center'>
        <h1 className='text-center my-5'>CSR</h1>
        <h5> our commitment to corporate social responsibility, as we make a positive impact on the communities we touch.</h5>
        <div className='container gallery__container'>
            {
            csrImages.map((csrImages, index) => {
                return <article key={index}>
                    <img src={csrImages} alt={'Gallery Image ${index +1}'} rel="noreffer noopener"/>
                </article>
            })
             }
        </div>
    </section>

    {/* Collaborations */}

    <section className='gallery text-center'>
        <h1 className='text-center my-5'>Collaborations</h1>
        <h5>Experience the power of synergy through our collaborative ventures that amplify creativity and innovation.</h5>
        <div className='container gallery__container'>
            {
            colImages.map((colImages, index) => {
                return <article key={index}>
                    <img src={colImages} alt={'Gallery Image ${index +1}'} rel="noreffer noopener"/>
                </article>
            })
             }
        </div>
    </section>
        <div className="contianer text-center my-5">
            <h4>Stay tuned for the latest updates, as our gallery is a canvas of inspirations and <br />achievements that reflect AHB Sumos' journey through the digital landscape.</h4>
        </div>
    </>
  )
}

export default Gallery
