import React from 'react'
import Image from '../images/trainer5.png'
// import {BsInstagram} from 'react-icons/bs'
// import {AiOutlineTwitter} from 'react-icons/ai'
// import {FaFacebookF} from 'react-icons/fa'
// import {FaLinkedinIn} from 'react-icons/fa' 
import './ksi.css'

function Oman() {
    let message = `Lorem ipsum, dolor sit amet consectetur adipisicing elit. Porro iste veniam hic atque perferendis fuga voluptate inventore dolor id, architecto explicabo! Similique, esse nesciunt! Molestias ut reprehenderit voluptate reiciendis ipsam!`;
  return (
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='section-title'>
                        Our Oman Team
                    </h2>
                    <p className='section-subtitle'>{message}</p>
                </div>

                <div className="row team-lead">
                    <div className="col-md-4"></div>
                    
                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Image} alt="" />
                        <h3>Malik Nayyar Abbas</h3>
                        <div className='team-info'>
                            <p className='founder'>Team Lead</p>
                            <p><span>Vision Statement: </span> To amplify Oman's essence in the digital realm, creating experiences that mirror the Sultanate's charm.</p>
                            <p><span>Profile Statement: </span>Malik Nayyar Abbas combines artistic flair with digital acumen, crafting solutions that mirror Oman's diverse culture.</p>
                            <p><span>Role: </span>Navigating the intersection of digital technology and Oman's identity, creating digital narratives that reflect the nation's spirit.</p>


                        </div>
                    </div>
                </div>
                </div>

            </div>
        </div>
    </section>
  )
}

export default Oman