import {Link} from 'react-router-dom'; 
import Logo from '../images/logo.png';
import { FaLinkedinIn } from 'react-icons/fa'
import { FaFacebookF } from 'react-icons/fa'
import { AiOutlineTwitter } from 'react-icons/ai'
import { AiFillInstagram } from 'react-icons/ai'


const Footer = () => {
  return (
    <footer>
        <div className="container footer__container">
            <article>
                <Link to="/" className='logo'>
                    <img src={Logo} alt="Footer Logo" />
                </Link>
             
                <div className="footer__socials">
                    <a href='https://linkedin.com/' target='blank'><FaLinkedinIn /></a>
                    <a href='https://facebook.com/' target='blank'><FaFacebookF /></a>
                    <a href='https://twitter.com/' target='blank'><AiOutlineTwitter /></a>
                    <a href='https://instagram.com/' target='blank'><AiFillInstagram /></a>
                </div>
            </article>
            <article>
                <h4>Permalinks</h4>
                <Link to="/about">About</Link>
                <Link to="/services">Services</Link>
                <Link to="/gallery">Gallery</Link>
                <Link to="/contact">Contact</Link>
            </article>           
             <article>
                <h4>Insights</h4>
                <Link to="/s">Blog</Link>
                <Link to="/s">Case Studies</Link>
                <Link to="/s">Events</Link>
                <Link to="/s">Communities</Link>
                <Link to="/s">FAQs</Link>
            </article>            
            <article>
                <h4>Get In Touch</h4>
                <Link to="/contact">Contact Us</Link>
                <Link to="/s">Support</Link>
            </article>        
        </div>
        <div className="footer__copyright">
            <small>2023 AHB-INNOVATIONS &copy; All Rights Reserved</small>
        </div>
    </footer>
  )
}

export default Footer