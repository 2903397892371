import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_3.png'
import {MdEmail} from 'react-icons/md'
import {BsMessenger} from 'react-icons/bs'
import {IoLogoWhatsapp} from 'react-icons/io'
import './contact.css'

const Contact = () => {
  
  return (
    <>

     <Header title="Get In Touch" image={HeaderImage}>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Eaque exercitationem nihil rem ullam adipisci nesciunt et impedit voluptatum. Nulla sunt aperiam dolores placeat cumque exercitationem consequatur omnis repellendus qui? Earum.
     </Header>
     <div className="container contact__headcontainer">
      <h2>Contact Us</h2>
     </div>
     <secton className="contact">
      <div className="container contact__container">
        <div className=" container contact__wrapper">
          <a href="mailto:ahbsumos@gmail.com" target="blank" className='container'><MdEmail/></a>
          <a href="https://m.me/#" target="blank" className='container'><BsMessenger/> </a>
          <a href="https://wa.me/+971544789198" target="blank" className='container'><IoLogoWhatsapp/> </a>
        </div>
      </div>
      
     </secton>
    </>
  )
}

export default Contact  




