import React from 'react'
import Image from '../images/trainer2.png'
import Imag from '../images/trainer4.png'
import './ksi.css'

function Dubai() {
    let message = ` `;
  return (
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='section-title'>
                        Our Dubai Team
                    </h2>
                    <p className='section-subtitle'>{message}</p>
                </div>

                <div className="row team-lead">
                    <div className="col-md-4"></div>
                    
                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Image} alt="" />
                        <h3>Ali Haider Bhutta </h3>
                        <div className='team-info'>
                            <p className='founder'>Team Lead</p>
                            <p><span>Vision Statement: </span> To lead AHB Sumos' Dubai team in crafting immersive digital experiences that resonate with the dynamic UAE landscape.</p>
                            <p><span>Profile Statement: </span>Ali Haider Bhutta fuses creative brilliance with strategic insight, steering our Dubai team towards digital excellence.</p>
                            <p><span>Role: </span>Navigating the digital arena with a visionary spirit, crafting experiences that embrace the essence of the UAE.</p>

{/* 
                            <ul className='team-icon'>
                            <i><a href='/'><BsInstagram/></a></i>
                                <i><a href='/'><AiOutlineTwitter /></a></i>
                                <i><a href='/'><FaFacebookF /></a></i>
                                <i><a href='/'><FaLinkedinIn /></a></i>
                            </ul> */}
                        </div>
                    </div>
                </div>
                </div>



                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Imag} alt="" />
                        <h3>Tayyab Rasheed</h3>
                        <div className='team-info'>
                            <p className='founder'>Marketing Head</p>
                            <p><span>Vision Statement: </span> To be the bridge between brands and their audience, delivering strategic marketing solutions that captivate and engage.</p>
                            <p><span>Profile Statement: </span> Tayyab Rasheed Bhatti's marketing acumen is a force to be reckoned with, shaping AHB Innovations Dubai presence through innovative strategies.</p>
                            <p><span>Role: </span>Illuminating brands in the digital landscape, curating marketing narratives that stand out in the UAE market.</p>


                            {/* <ul className='team-icon'>
                            <i><a href='/'><BsInstagram/></a></i>
                                <i><a href='/'><AiOutlineTwitter /></a></i>
                                <i><a href='/'><FaFacebookF /></a></i>
                                <i><a href='/'><FaLinkedinIn /></a></i>
                            </ul> */}
                        </div>
                    </div>
                </div>
      

            </div>
        </div>
    </section>
  )
}

export default Dubai