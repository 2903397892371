import './trainers.css'
import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_5.png'
import './trainers.css'
import MainT from '../../components/MainT'
// import KSI from '../../components/KSI'
// import Dubai from '../../components/Dubai'
// import Lahore from '../../components/Lahore'
// import Oman from '../../components/Oman'



const Trainers = () => {
  return (
    <>

    <Header title="Our Team" image={HeaderImage}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis commodi quibusdam dolorum voluptatibus 
        quia consequatur laborum, incidunt nobis sint aliquid eveniet ratione explicabo distinctio magnam officiis 
        optio quasi neque modi!
    </Header>

    <MainT />

{/* 
    <div className="container team__headcontainer">
        <h2>Our Team</h2>
    </div>
    <section className="trainers">
    <div className="contianer">
    <KSI/>
    </div>
    <div className="contianer">
    <Dubai/>
    </div>
    <div className="contianer">
    <Lahore/>
    </div>
    <div className="contianer">
    <Oman/>
    </div>

    </section> */}
    </>
  )
}




export default Trainers
