import {Link} from 'react-router-dom'
import Image from '../images/main_header.png'


const MainHeader = () => {
  return (
    <>
    <header className="main__header">
      <div className="container main__header-container">
        
        <div className="main__header-left">
          <h4>Revolutionizing Brands through Digital Aesthetics</h4>
          <h1>We Are AHB INNOVATIONS</h1>
          <p>Welcome to AHB Innovations Digital Media, where creative visions come to life! We are not just a digital agency; we are the architects of your digital success. Our approach blends innovation with strategic thinking to craft digital experiences that captivate your audience and drive tangible results.</p>
          <Link to="/services" className='btn lg'>Get Started</Link>
        </div> 

        <div className="main__header-right">
          <div className="main__header-circle"></div>
            <div className="main__header-image">
              <img src={Image} alt="Main Header" />
            </div>
        </div>      
      </div>
    </header>
    </>
  )
}

export default MainHeader