import React from 'react'
import Image from '../images/trainer3.png'
import Imag from '../images/trainer6.png'
import './ksi.css'

function Lahore() {
    let message = ` `;
  return (
    <section>
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h2 className='section-title'>
                        Our Lahore Team
                    </h2>
                    <p className='section-subtitle'>{message}</p>
                </div>

                <div className="row team-lead">
                    <div className="col-md-4"></div>
                    
                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Image} alt="" />
                        <h3>Syed Ammar Haider</h3>
                        <div className='team-info'>
                            <p className='founder'>Team Lead</p>
                            <p><span>Vision Statement: </span> To craft digital marvels that transform industries, leaving a trail of innovation and excellence.</p>
                            <p><span>Profile Statement: </span> : An advocate for pushing digital boundaries, Syed Ammar Haider combines strategic thinking with technological mastery to lead our Lahore team.</p>
                            <p><span>Role: </span>: Orchestrating digital symphonies that resonate with clients and drive results.</p>


                            {/* <ul className='team-icon'>
                            <i><a href='/'><BsInstagram/></a></i>
                                <i><a href='/'><AiOutlineTwitter /></a></i>
                                <i><a href='/'><FaFacebookF /></a></i>
                                <i><a href='/'><FaLinkedinIn /></a></i>
                            </ul> */}
                        </div>
                    </div>
                </div>
                </div>



                <div className="col-sm-6 col-md-4">
                    <div className="team-item">
                        <img src={Imag} alt="" />
                        <h3>Jawad Sherazi</h3>
                        <div className='team-info'>
                            <p className='founder'>Senior Developer</p>
                            <p><span>Vision Statement: </span> To translate creative concepts into functional digital realities, redefining user experiences.</p>
                            <p><span>Profile Statement: </span> Syed Jawad Sherazi's code is a testament to his passion for flawless web development, adding life to designs and functionality to dreams.</p>
                            <p><span>Role: </span>Breathing life into digital visions, transforming ideas into interactive digital journeys.</p>


                            {/* <ul className='team-icon'>
                            <i><a href='/'><BsInstagram/></a></i>
                                <i><a href='/'><AiOutlineTwitter /></a></i>
                                <i><a href='/'><FaFacebookF /></a></i>
                                <i><a href='/'><FaLinkedinIn /></a></i>
                            </ul> */}
                        </div>
                    </div>
                </div>
      

            </div>
        </div>
    </section>
  )
}

export default Lahore