import Image from '../images/values.jpg'
import Sectionhead from './Sectionhead'
import {GiCutDiamond} from 'react-icons/gi'
import {values} from '../data'
import Card from '../UI/Card'


const Values = () => {
  return (
    <section className="values">
        <div className="container values__container">
            <div className="values__left">
                <div className="values__image my-5">
                    <img src={Image} alt="Our Values" />
                </div>
            </div>
            <div className="values__right">
                <Sectionhead icon={<GiCutDiamond/>} title="Our Values" />
                    <p>Join us on a journey where pixels meet passion, and code transforms into creativity. Explore our services, discover our projects, and be a part of the AHB innovations experience. Let's elevate your brand's digital presence together.
                    </p>
                    
                    <div className="values__wrapper">
                        {
                            values.map(({id, icon, title, desc}) => {
                                return (
                                    <Card className="values__value" key={id}>
                                        <span>{icon}</span>
                                        <h4>{title}</h4>
                                        <small>{desc}</small>
                                    </Card>
                                    )
                            })
                        }
                    </div>

                    {/* <div className="container brand__container">
                        <p>Ready to Elevate Your Brand? Contact us today and embark on a digital journey like never before.</p>
                    </div> */}
            </div>
          
        </div>
       
    </section>
  )
}

export default Values


