import React from 'react'
import KsiTeam from '../../components/KsiTeam'
import Header from '../../components/Header'
import HeaderImage from '../../images/header_bg_5.png'

const Ksi = () => {
  return (
    <>
    <Header title="Our Team" image={HeaderImage}>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Reiciendis commodi quibusdam dolorum voluptatibus 
        quia consequatur laborum, incidunt nobis sint aliquid eveniet ratione explicabo distinctio magnam officiis 
        optio quasi neque modi!
    </Header>

        <KsiTeam />
    </>
  )
}

export default Ksi