import {FaCrown} from 'react-icons/fa'
import Sectionhead from './Sectionhead'
import {programs} from '../data'
import Card from '../UI/Card'
import {Link} from 'react-router-dom'

const Programs = () => {
  return (
    <section className="programs">
        <div className="container programs__container">
              <Sectionhead icon={<FaCrown/>} title="Services" />
            <div className="programs__warpper">
                {
                    programs.map(({id, icon, title, info, path}) => {
                        return (
                            <Card className="programs__program" key={id}>
                                <span>{icon}</span>
                                <h4>{title}</h4>
                                <small>{info}</small>
                                <Link to="/contact" className="btn sm">Learn More</Link>
                            </Card> 
                        )
                    })
                }
            </div>
        </div>
    </section>
  )
}

export default Programs