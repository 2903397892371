import React from 'react'
import Image from '../images/trainer2.png'
import Imag from '../images/trainer1.png'
import './ksi.css'

function Dubai() {

  return (
    <section>
        
        <div className="container">
            <div className="row">
            <div className="col-md-12 text-center fo__container">
                    <h2 className='section-title'>
                        Founder And CEO
                    </h2>
                </div>
              <div className="row team-lead">


              <div className="col-sm-6 col-md-5">
                    <div className="team-item">
                        <img src={Imag} alt="" />
                        <h3>Akhtar Hussain Bukhari </h3>
                        <div className='team-info'>
                            <p className='founder'>Founder</p>
                            <p><span>Vision Statement: </span>To revolutionize the digital landscape by infusing creativity and innovation into every project, leaving an indelible mark on the world of digital media.</p>
                            <p><span>Profile Statement: </span> A visionary with a passion for transforming ideas into digital brilliance, AHB has pioneered AHB Innovations journey with a commitment to redefining possibilities.</p>
                            <p><span>Role:  </span>Charting the course of our digital voyage and setting the stage for creativity and innovation to thrive.</p>
                        </div>
                    </div>
                </div>

                    <div className="col-md-2    "></div>
                
                    <div className="col-sm-6 col-md-5">
                    <div className="team-item">
                        <img src={Image} alt="" />
                        <h3>Ali Haider Bhutta </h3>
                        <div className='team-info'>
                            <p className='founder'>C.E.O</p>
                            <p><span>Vision Statement: </span> To lead AHB Sumos in becoming the catalyst of change in the digital realm, fostering creativity, excellence, and strategic brilliance.</p>
                            <p><span>Profile Statement: </span> An advocate of digital transformation, AHB is driven by the goal of igniting brands' online presence through a fusion of technology and creativity.</p>
                            <p><span>Role: </span>Steering the ship of digital brilliance, orchestrating strategies that connect brands with their audience.</p>

                        </div>
                    </div>
                </div>
                </div>
    
            </div>
        </div>
    </section>
  )
}

export default Dubai